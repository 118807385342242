import React, { useContext, useMemo } from 'react'
import firebase from "gatsby-plugin-firebase"
import "firebase/firestore"
import { useCollectionOnce } from "react-firebase-hooks/firestore"
import { ProfilePreview, fromDbObj } from '@cruto/cruto-core'
import ProfilePreviewComponent from '../../common/profilePreview/ProfilePreview'

const numOf = 4

type Props = {
    body: {
        html: string
    }
}

const Body = ({ body }: Props) => {

    const [value, loading] = useCollectionOnce(
        firebase
            .firestore()
            .collection(`profilePreviews`)
            .limit(numOf)
    )

    const profiles: (ProfilePreview | null)[] = useMemo(() => {

        if (!loading && value) {
            return value.docs.map(e => fromDbObj(e.data()))
        }

        return new Array(numOf).fill(null)
    }, [loading, value])

    return (
        <>
            <div className="container py-5">
                <div dangerouslySetInnerHTML={{ __html: body.html }} />
            </div>
            <div className="container pb-5">
                <ProfilePreviewComponent profiles={profiles} />
            </div>
        </>
    )
}

export default Body