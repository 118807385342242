import classNames from "classnames"
import React, { useContext } from "react"
import AppContext from "../../../common/appContext"
import { getClientTranslations } from "../../layout/helperFunctions"
import Card from "./card"

export type CardType = {
  heading: string
  body: string
}

type CardsSectionProps = {
  cards: CardType[]
  className?: string
  headingClassName?: string
}

const CardsSection = ({ cards, className, headingClassName }: CardsSectionProps) => {

  const { locale } = useContext(AppContext)

  return <section className="row gy-4">

    <h3 className={`pb-3 ${headingClassName}`}>
      {getClientTranslations("Why Cruto", locale)}?
    </h3>


    {cards.map((c, i) => (
      <div className="col-12 col-md-4" key={i}>
        <div data-sal="zoom-in" data-sal-delay="300" data-sal-easing="ease">
          <Card
            heading={c.heading}
            body={c.body}
            className={classNames("shadow", className)}
          />
        </div>
      </div>
    ))}
  </section>
}



export default CardsSection
