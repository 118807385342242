import React, { useMemo } from 'react'
import { concatWithSeparator, Grid, Typography } from '@cruto/cruto-core'
import PlaceIcon from "@material-ui/icons/Place"

type Props = {
    municipalities: string[]
}

const Address = ({ municipalities }: Props) => {

    const displayString: string | null = useMemo(() => {
        if (municipalities.length > 0) {


            return concatWithSeparator(", ", ...municipalities)
        }

        return null
    }, [municipalities])

    if (displayString) {

        return <Grid container paddingTop="1rem" paddingBottom="1rem">
            <Grid item xs={12} display="flex" alignItems="center">
                <PlaceIcon />
                <Typography
                    className="ps-2"
                    variant="body1" >
                    {displayString}
                </Typography>
            </Grid>
        </Grid>
    }

    return null
}

export default Address