import { ProfilePreview, Skeleton } from '@cruto/cruto-core'
import React from 'react'
import Address from './Address'
import Skills from './Skills'
import Occupations from './Occupations'
import { OutboundLink } from "gatsby-plugin-google-gtag-optin"

type Props = {
    profile: ProfilePreview | null
}

const ProfilePreviewCard = ({ profile }: Props) => {

    if (profile) {
        return (

            <OutboundLink
                style={{ opacity: "1" }}
                href={`https://app.cruto.io/profile/${profile.id}`}>

                <div
                    className={"move-top-on-hover p-3 rounded u-bg-white shadow"}
                >
                    {
                        profile.occupations &&
                        <Occupations occupations={profile.occupations} />
                    }

                    <Address municipalities={profile.municipalies} />

                    <Skills skills={profile.skills} />
                </div>

            </OutboundLink>
        )
    }

    return (
        <div
            className={"move-top-on-hover p-3 rounded u-bg-white shadow"}
        >
            <Skeleton
                variant="text"
                height={45.58}
                width={"100%"}
                style={{ marginBottom: ".5rem" }}
            />
            <Skeleton
                variant="text"
                height={25.59}
                width={"100%"}
                style={{ marginBottom: "1rem" }}
            />
        </div>
    )
}

export default ProfilePreviewCard