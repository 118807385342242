import classNames from "classnames"
import React from "react"
import { UiColor } from "../../../common/types/color"

type CardProps = {
  heading: string
  body: string
  className?: string
  color?: UiColor
  onClick?: () => void
}

const Card = ({ heading, body, className, color, onClick }: CardProps) => {
  const colorClassName: string = color ? `u-c-${color}` : null

  const handleClick = () => {
    if (onClick) {
      onClick()
    }
  }

  return (
    <div
      onClick={handleClick}
      className={classNames(
        "move-top-on-hover p-3 rounded d-flex align-items-center justify-content-center text-center min-vh-15 flex-column",
        className
      )}
    >
      <p
        className={classNames("fw-bold u-o-1", {
          [colorClassName]: colorClassName,
        })}
      >
        {heading}
      </p>
      <p className={classNames({ [colorClassName]: colorClassName })}>{body}</p>
    </div>
  )
}

export default Card
