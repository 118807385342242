import classNames from "classnames"
import React from "react"
import { BlogPostType } from "../blog/blogPostSection"
import PictureTemplate from "./pictureTemplate"

type StepsProps = {
  posts: BlogPostType[]
}

const Steps = ({ posts }: StepsProps) => (
  <div className="align-items-center my-5 container container-small d-flex flex-column position-relative">
    {posts.map((p, i) => (
      <article
        key={i}
        className={classNames("row py-5", "min-w-100", {
          "pt-0": i === 0,
        })}
      >
        <>
          <div
            data-sal="slide-up"
            data-sal-delay="400"
            data-sal-easing="ease"
            className={classNames("col-10 col-md-5 px-md-5", {
              "order-last": i % 2 !== 0,
            })}
          >
            <h5>{p.heading}</h5>
            <p className="lead-md">{p.summary}</p>
          </div>
          <div className="align-items-center col-2 d-flex h-25 p-0 justify-content-sm-center order-first order-md-0">
            <div
              style={{ zIndex: 2 }}
              className="shadow rounded px-3 py-2 px-md-4 py-md-3 u-bg-white"
            >
              <span className="lead-md">{i + 1}</span>
            </div>
          </div>
          <div
            data-sal="slide-up"
            data-sal-delay="400"
            data-sal-easing="ease"
            className={classNames("d-none d-md-block col-5 px-5", {
              "order-first": i % 2 !== 0,
            })}
          >
            {p.picture && <PictureTemplate {...p.picture} />}
          </div>
        </>
      </article>
    ))}

    <div className="how-it-works-line"></div>
  </div>
)

export default Steps
