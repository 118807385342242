import { concatWithSeparator, Grid, ProfilePreviewConcept, Typography } from '@cruto/cruto-core'
import React, { useContext, useMemo } from 'react'
import AppContext, { Locale } from '../appContext'
import WorkIcon from "@material-ui/icons/Work"

type Props = {
    occupations: ProfilePreviewConcept[]
}

const Occupations = ({ occupations }: Props) => {

    const { locale } = useContext(AppContext)

    const displayString: string | null = useMemo(() => {

        if (occupations.length > 0) {

            const arr: (string | undefined)[] = locale === Locale.sv ? occupations.map(o => o.label) : occupations.map(o => o.labelEn ?? o.label)

            const result: string = concatWithSeparator(", ", ...arr)

            return result
        }

        return null
    }, [occupations, locale])

    if (displayString) {

        return (

            <Grid container paddingBottom="1rem">
                <Grid item xs={12} display="flex" alignItems="center">
                    <Typography
                        className="ps-2 text-break"
                        variant="h6"
                        fontWeight="bold"
                        >
                        {displayString}
                    </Typography>
                </Grid>
            </Grid>
        )
    }

    return null
}

export default Occupations