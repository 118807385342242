import React, { CSSProperties } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { PictureData } from "../../common/types/pictureData"

type PictureTemplateProps = {
  imgStyle?: CSSProperties
  style?: CSSProperties
  className?: string
} & PictureData

const PictureTemplate = (props: PictureTemplateProps) =>
  (props.localFile?.childrenImageSharp?.length ?? 0) > 0 ? (
    <GatsbyImage
      imgStyle={props.imgStyle}
      style={props.style}
      className="rounded"
      image={props.localFile.childrenImageSharp[0].gatsbyImageData}
      alt={props.alt}
    />
  ) : props.localFile?.childImageSharp ? (
    <GatsbyImage
      imgStyle={props.imgStyle}
      style={props.style}
      className="rounded"
      image={props.localFile.childImageSharp.gatsbyImageData}
      alt={props.alt}
    />
  ) : (
    <img
      style={props.style}
      className={props.className}
      src={props.url}
      alt="blog pic"
    />
  )

export default PictureTemplate
