

import React from 'react'
import { ProfilePreview as ProfilePreviewType } from '@cruto/cruto-core'
import ProfilePreviewCard from './ProfilePreviewCard'

type Props = {
    profiles: (ProfilePreviewType | null)[]
}

const ProfilePreview = ({ profiles }: Props) => {

    return (
        <div className="px-3">
            {profiles.map((e, i) => {
                return (
                    <div key={i} className="row py-3">
                        <div className={`col-12`}>
                            <ProfilePreviewCard profile={e} />
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default ProfilePreview