import React from "react"
import { useContext } from "react"
import AppContext, { Locale } from "../appContext"
import { Grid, Typography, ProfilePreviewConcept, Box, colorOrange, displayConceptStatus } from "@cruto/cruto-core"

type Props = {
    skills: ProfilePreviewConcept[]
}

const Skills = ({ skills }: Props) => {
    const { locale } = useContext(AppContext)


    if (skills.length > 0) {

        return (

            <Grid container className="p-2">

                {skills.map((e, i) => {

                    return <Grid
                        item
                        xs={12}
                        container
                        key={i}
                        paddingTop={"1rem"}
                        alignItems="end"
                    >
                        <Grid item xs={3} md={2}>
                            <Typography
                                variant="body2"
                                fontWeight={"bold"}
                                lineHeight={1.2}
                            >
                                {locale === Locale.sv ? e.label : e.labelEn ?? e.label}:
                            </Typography>
                        </Grid>
                        <Grid item xs={9} md={10} container alignItems="end">
                            <Box
                                sx={{
                                    width: `${e.status * 15}%`,
                                    height: "1rem",
                                    backgroundColor: colorOrange,
                                    borderRadius: "1rem",
                                    marginRight: ".5rem",
                                }}
                            />
                            <Typography
                                variant="body2"
                                fontWeight={"bold"}
                                lineHeight={1.2}
                            >
                                {displayConceptStatus(e.status)}
                            </Typography>
                        </Grid>
                    </Grid>
                })}

            </Grid>
        )
    }

    return null
}
export default Skills
